import React from 'react';
import imgp1 from '../resource/p1.jpg';
import imgp2 from '../resource/p2.jpg';
import imgp3 from '../resource/p3.jpg';
import imgp4 from '../resource/p4.jpg';
import imgp5 from '../resource/p5.jpg';

const HomeCarousel = () => {
  return (
<>
<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="true">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 3"></button>

  </div>
  <div class="carousel-inner carousel-div-bg-color" >
    <div class="carousel-item active">
      <img src={imgp1} class="d-block w-75" alt="First Slide"/>
    </div>
    <div class="carousel-item">
    <img src={imgp2} class="d-block w-75" alt="Second Slide"/>
    </div>
    <div class="carousel-item">
    <img src={imgp3} class="d-block w-75" alt="Third Slide"/>
    </div>
    <div class="carousel-item">
    <img src={imgp4} class="d-block w-75" alt="Fourth Slide"/>
    </div>
    <div class="carousel-item">
    <img src={imgp5} class="d-block w-75" alt="Fifth   Slide"/>
    </div>


  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>



    </>
  );
};

export default HomeCarousel;
