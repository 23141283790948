import React, { useState,useEffect } from 'react';

const About = () => {
    return (
        <div>
            <br/>
            <br/><br/><br/>
            <h1 className="text-primary">About Us</h1>
            <p>As gamechangers in the business, we deliver an industry-leading portfolio of products, services, and solutions dedicated to empowering your digital transformation journeys.
                With a business strategy that’s aligned to core values, and backed by technology, people, and competitive capabilities, we help you deliver disruption and differentiation in an evolving landscape.
            </p>
            <br/><br/>

            <h3 className="text-primary">What We Do</h3>
            <p>Transforming Your Digital Aspirations, Realizing Retuns On Digital
            At Parvati Info Tech, we are dedicated to consistently maximizing your digital potential, helping you achieve tangible results. Our innovative solutions and expert guidance are crafted to bring your vision to life. </p>


        </div>
    );
};

export default About;
