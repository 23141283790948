import React, { useState,useEffect } from 'react';
import CallnowButton from './CallnowButton';
import WhatsappButton from './WhatsappButton';
import HomeCarousel from './HomeCarousel';
import ImageCarousel from './ImageCaraousel';

const HomePage = () => {


    return (
        <div width="80%">
            <h3 className="text-primary">Welcome to Parvati Info Tech</h3>
            
            <br/>
            
            <HomeCarousel></HomeCarousel><br/>

            <h2>Elevate Your Business with Cutting-Edge Digital Solutions</h2>
            <br/><br/>            
            <h4>Empower your business with our tailored software solutions</h4>
            
            In today's digital age, a strong online presence is essential for business success. 
            At ParvatiInfoTech, we provide a comprehensive suite of software solutions to help you
             streamline operations, enhance customer experiences, and drive growth.
            <br/>
            <br/><br/>
            <h4> Our Services:</h4>

            <br/><b>POS Software:</b> Revolutionize your point-of-sale operations with our efficient and user-friendly POS software.
            <br/><b>Custom Websites:</b> Create a stunning online presence that reflects your brand identity with our bespoke website development services.
            <br/><b>Custom Mobile Apps:</b> Engage your customers on the go with our innovative mobile app development solutions.
            <br/><b>Ecommerce Software:</b> Sell your products online seamlessly with our powerful ecommerce software.
            <br/><b>Billing Systems:</b> Simplify your billing processes and improve financial management with our robust billing systems.
            <br/><b>Inventory Systems:</b> Optimize your inventory management and reduce costs with our advanced inventory systems.

    <br/>
            <br/><br/>
            <h4> Why Choose Us?</h4>

            <br/><b>Tailored Solutions:</b> We understand that every business is unique. Our solutions are customized to meet your specific needs.
            <br/><b>Expert Team:</b> Our skilled team of developers and designers are committed to delivering exceptional results.
            <br/><b>State-of-the-Art Technology:</b> We leverage the latest technologies to provide cutting-edge solutions.
            <br/><b>Excellent Customer Support:</b> Our dedicated support team is always ready to assist you.

            <br/><br/><b>Ready to take your business to the next level?</b>
            <br/><p className="text-primary">Contact us today to discuss your project requirements.</p>
            <CallnowButton  />
            <WhatsappButton/>
        </div>
    );
};

export default HomePage;
