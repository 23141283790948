import React, { Component } from "react";
import {Route} from 'react-router-dom';
import {Link} from 'react-router-dom';
import parvatinfotechimage from '../parvatinfotechimage.jpeg';


const Navbar = () => {
    const navItems = [
        { name: "Home", url: "/" },
        { name: "Services", url: "/services" },
        { name: "Contact", url: "/contact" },
        { name: "About Us", url: "/about" }
    ];

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a href="/"><img src={parvatinfotechimage} alt="app logo" width="150" height="100"/> </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ">
                    {navItems.map((item, index) => (
                        <li className="nav-item" key={index}>
                            <Link className="nav-link " to={item.url}>{item.name}</Link>
                        </li>
                    ))}
                </ul>
            </div>
            
        </nav>
    );
};

export default Navbar;


