import React, { useState,useEffect } from 'react';
import CallnowButton from './CallnowButton';
import WhatsappButton from './WhatsappButton';
import imgContactus from '../resource/contactus.jpg';

const Contact = () => {
    return (
        <div>
            <br/><br/>
            <h2 className="text-primary">Contact Us</h2>
            <br/><br/>
            <img   src={imgContactus}  width="500" Height="250" alt="Cart"  />

            <p>Parvati Info Tech, Your partner in custom solutions using the latest technologies.</p>
            <p>Email: rksonijpr@yahoo.com</p>

            <CallnowButton  />
            <WhatsappButton/>

        </div>
    );
};

export default Contact;
