import React, { useState,useEffect } from 'react';

const Services = () => {
    return (
        <div >
            <blockquote>
            <br/><br/><br/><br/>
            <h1 className="text-primary"> Our Services</h1>
            <p></p>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">Custom Software Development</li>
                <li className="list-group-item">Web Application Development</li>
                <li className="list-group-item">Mobile Application Development</li>
                <li className="list-group-item">Cloud Solutions</li>
                <li className="list-group-item">IT Consulting</li>
            </ul>
            
            <br/><br/>
            <br/><b>POS Software:</b> Revolutionize your point-of-sale operations with our efficient and user-friendly POS software.
            <br/><b>Custom Websites:</b> Create a stunning online presence that reflects your brand identity with our bespoke website development services.
            <br/><b>Custom Mobile Apps:</b> Engage your customers on the go with our innovative mobile app development solutions.
            <br/><b>Ecommerce Software:</b> Sell your products online seamlessly with our powerful ecommerce software.
            <br/><b>Billing Systems:</b> Simplify your billing processes and improve financial management with our robust billing systems.
            <br/><b>Inventory Systems:</b> Optimize your inventory management and reduce costs with our advanced inventory systems.

            <br/>
            <br/><br/>
            <h4> Why Choose Us?</h4>

            <br/><b>Tailored Solutions:</b> We understand that every business is unique. Our solutions are customized to meet your specific needs.
            <br/><b>Expert Team:</b> Our skilled team of developers and designers are committed to delivering exceptional results.
            <br/><b>State-of-the-Art Technology:</b> We leverage the latest technologies to provide cutting-edge solutions.
            <br/><b>Excellent Customer Support:</b> Our dedicated support team is always ready to assist you.



            </blockquote>
        </div>
    );
};

export default Services;
