import React, { useState,useEffect } from 'react';


const WhatsappButton = () => {
  const handleCallClick = () => {
    window.open('https://wa.me/918619793290');
  };

  return (
    <button className="btn btn-primary m-2" onClick={handleCallClick}>Connect on Whatsapp</button>
  );
};

export default WhatsappButton;