import React, { useState,useEffect } from 'react';


const CallnowButton = () => {
  const handleCallClick = () => {
    window.location.href = 'tel:8619793290';
  };

  return (
    <button className="btn btn-primary m-2" onClick={handleCallClick}>Call Now</button>
  );
};

export default CallnowButton;