import logo from './logo.svg';
import './App.css';

import React ,{Component}from 'react';

import { BrowserRouter,  Routes,Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';   
import 'jquery/dist/jquery.min.js';

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Sidebar from './component/sidebar';
import Titlebar from './component/Titlebar';
import Home from './component/HomePage';
import About from './component/About';
import Services from './component/Services';
import Contact from './component/Contact';
import Navbar from './component/Navbar';
import HomePage from './component/HomePage';



class App extends Component {
  
  

  constructor(props) {
    super(props);
    this.state = {
          username: "",
          ItemCount: 2
    }
  }

  

  setUsername = (username) => {
    this.setState({username});
    
  } 

  render(){

     //    <Route path="/itemdetail/:id" component={ItemDetailForm}></Route>
    //<Route path="/itemdetail/:id" render={(props) => <ItemDetailForm {...props} />}/> 

//             <Route path="/" element={<TestComp2 {...this.props}  />}/> 

    

    let {item}=this.state;


    return(
      <>
      <div className="App"> 
        
        <Navbar />

      </div>
      <table >
        <tbody>
        <tr><td className='col-2' align='center' valign='top' width="100%">
          
        </td>
        <td className='col'  width="100%">
          <ToastContainer/>

          <Routes>
            <Route path="/about" element={<About/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/" exact element={<HomePage/>} />
          </Routes>
          
         <br/> 
        </td></tr>
        </tbody>
      </table>
      </>

    
    )
  }
}
export default App;


//From app.js: {JSON.stringify(this.state.item)}